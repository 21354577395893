<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <!-- HERO -->
      <div class="container hero-container">
        <Hero class="section__hero" :images="$path('media.cover', item)">
          <template #stats-item="{ item }">
            <RichText :value="item" />
          </template>
        </Hero>
      </div>

      <!-- INTRO -->
      <Section :mobile-collapse="false">
        <div class="intro-text">
          <span v-if="getIntroText('Beliggenhet')">{{ getIntroText('Beliggenhet') }}</span>
          <span v-if="getIntroText('Type lokale')">{{ getIntroText('Type lokale') }}</span>
          <span v-if="getIntroText('Totalt areal')">{{ getIntroText('Totalt areal') }}</span>
        </div>
        <h1
          style="margin-block: 1rem"
          v-html="parseDescription(projectDescription).title || item.name"
        ></h1>
        <RichText
          v-if="richTextNotEmpty(parseDescription(projectDescription).description)"
          :value="parseDescription(projectDescription).description"
          class="intro-description"
        />
      </Section>

      <!-- NØKKELINFORMASJON -->
      <Section v-if="keyInfo.length" title="Nøkkelinfo" scroll-id="nokkelinfo">
        <Grid :columns="3" align="start">
          <List :items="keyInfo" style="grid-column: span 2" />
        </Grid>
      </Section>

      <!-- BELIGGENHET -->
      <Section
        v-if="locationText"
        :title="parseDescription(locationText).title || 'Beliggenhet'"
        :description="parseDescription(locationText).description"
        scroll-id="beliggenhet"
      >
        <Grid :columns="1">
          <MapComponent v-if="$path('address', item)" :data="$path('address', item)" />

          <Grid v-if="locationFeatures.length" :columns="3" style="margin-top: 3rem">
            <h3>Kollektivtransport</h3>
            <List :items="locationFeatures" style="grid-column: span 2" />
          </Grid>
        </Grid>
      </Section>

      <!-- PLANTEGINGER -->
      <Section
        v-if="$path('images.length', plans)"
        :title="parseDescription(plans.content).title || 'Plantegninger'"
        :description="parseDescription(plans.content).description"
        scroll-id="plantegninger"
      >
        <Slider :auto-play="false" :images="plans.images" size="contain" />
      </Section>

      <!-- FASILITETER -->
      <Section
        v-if="richTextNotEmpty(facilitiesText) || facilitiesFeatures.length || gallery.length"
        :title="parseDescription(facilitiesText).title || 'Fasiliteter'"
        :description="parseDescription(facilitiesText).description"
        scroll-id="fasiliteter"
      >
        <FacilitiesFeatures :value="facilitiesFeatures" />

        <template v-if="gallery.length">
          <h3>Bildekarusell</h3>
          <Slider :images="gallery" />
        </template>
      </Section>

      <!-- BÆREKRAFT -->
      <Section
        v-if="environment && environment.content"
        :title="parseDescription(environment.content).title || 'Bærekraft & miljø'"
        :description="parseDescription(environment.content).description"
        scroll-id="barekraft"
      >
        <Grid v-if="environment.images && environment.images.length" :columns="3">
          <Gallery :images="environment.images" class="environment-gallery" />
        </Grid>
      </Section>

      <!-- GÅRDEIER -->
      <Section
        v-if="owner && owner.content"
        :title="parseDescription(owner.content).title || 'Om gårdeier'"
        :description="parseDescription(owner.content).description"
        scroll-id="gardeier"
      >
        <Gallery v-if="owner.logo" :images="[owner.logo]" class="owner-gallery" />
      </Section>

      <!-- KONTAKT MEGLER -->
      <Section
        :title="isMobile ? parseDescription(leadContent).title || 'Kontakt megler' : null"
        scroll-id="kontakt"
      >
        <Grid :columns="2" align="start" class="contact-grid">
          <Roles :value="$path('roles.salesman', item) || []" />
          <Lead>
            <h2>{{ parseDescription(leadContent).title || 'Kontakt megler' }}</h2>
            <RichText
              v-if="richTextNotEmpty(leadContent)"
              :value="parseDescription(leadContent).description"
            />
          </Lead>
        </Grid>
      </Section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { GetCustomField, TransformTag } from '@/utils'
import BrowserApiMixin from '@/mixins/browser-api'

import Hero from '@/components/Hero'
import RichText from '@/components/RichText'
import Grid from '@/components/Grid'
import Section from '@/components/Section'
import FacilitiesFeatures from '@/components/FacilitiesFeatures'
import MapComponent from '@kvass/pagebuilder/src/components/Map.vue'
import Roles from '@/components/Roles'
import Lead from '@/components/Lead'
import List from '@/components/List'
import Gallery from '@/components/Gallery'
import Slider from '@/components/Slider'

export default {
  mixins: [BrowserApiMixin('project')],
  data() {
    return {
      iconMap: {
        flexibleSolutions: { icon: ['fa', ''], size: '2x' },
      },
    }
  },
  computed: {
    ...mapState('Project', ['promises', 'item']),
    projectDescription() {
      return this.$path('item.description')
    },
    rentProperties() {
      return this.$path('item.customFields.rent-properties') || {}
    },
    rentLocation() {
      return this.$path('item.customFields.rent-location') || {}
    },
    projectRentProperties() {
      return this.$path('item.customFields.project-rent-properties') || {}
    },
    keyInfo() {
      const includedKeys = [
        'propertyType',
        'totalArea',
        'acquisition',
        'officeSpaces',
        'owner',
        'address',
        'location',
        'floor',
      ]

      let address = this.$path('address', this.item) || {}
      address = `${address.street || ''}, ${address.postcode || ''} ${address.city || ''}${
        address.county ? ', ' + address.county : ''
      }`

      function filterNumber(number) {
        if (!number) return
        return number.toLocaleString().replace(',', ' ')
      }
      let totalArea = () => {
        let data = this.projectRentProperties

        if (!data || !data.areaFrom) return

        return `${this.$tc('from')} ${filterNumber(data.areaFrom)} ${
          data.areaTo ? this.$tc('to').toLowerCase() + ' ' + filterNumber(data.areaTo) : ''
        } m²`
      }

      const entries = Object.entries({
        ...this.rentProperties,
        ...this.projectRentProperties,
        ...this.rentLocation,
        totalArea: totalArea(),

        address,
      })
        .filter(([key, value]) => includedKeys.includes(key) && !!value)
        .filter(([key, value]) => Boolean(value && value.length))

      const prefix = key => (key === 'propertyType' ? 'rent-properties-propertyType:' : '')
      const parseValue = (val, prefix = '') => {
        val = val instanceof Array ? val : [val]
        return val.map(v => this.$tc(`${prefix}${v}`)).join(', ')
      }

      return entries.map(([key, value]) => ({
        key: this.$tc(`rent-properties-${key}`),
        value: parseValue(value, prefix(key)),
      }))
    },
    facilitiesText() {
      return this.$path('item.customFields.rent-facilities.content')
    },
    gallery() {
      return this.$path('item.media.gallery') || []
    },
    facilitiesFeatures() {
      return this.$path('item.customFields.rent-facilities.features') || []
    },
    environment() {
      return this.$path('item.customFields.rent-environment')
    },
    owner() {
      return this.$path('item.customFields.rent-owner')
    },
    locationText() {
      return this.$path('item.customFields.rent-location.content') || ''
    },
    locationFeatures() {
      let features = this.$path('item.customFields.rent-location-features')
      if (!features) return []
      return (features || [])
        .map(f => ({
          key: f.title,
          value: f.subtitle,
        }))
        .filter(i => Boolean(i.key))
    },
    plans() {
      return this.$path('item.customFields.rent-floorplans') || {}
    },
    leadContent() {
      return this.$path('item.customFields.lead-content')
    },
    isMobile() {
      return window.innerWidth < 900
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    TransformTag,
    richTextNotEmpty(value) {
      if (!value) return false
      return value != '<p></p>'
    },
    getIntroText(key) {
      return this.keyInfo.find(info => info.key === key)?.value
    },
    parseDescription(text) {
      if (!text) return { title: null, description: null }

      const regex = /^\<h2\>(.+?)\<\/h2\>/
      let title = text.match(regex)?.[1] || null
      if (title) {
        ;['amp;', '<strong>', '</strong>'].every(i => (title = title.replace(i, '')))
      }
      const description = TransformTag(text.replace(regex, ''), ['h2', 'strong']) || null

      return { title, description }
    },
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Hero,
    RichText,
    Grid,
    Section,
    FacilitiesFeatures,
    MapComponent,
    Roles,
    Lead,
    List,
    Gallery,
    Slider,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  @include respond-above('tablet') {
    $gradient: 4%;
    background-image: linear-gradient(
      180deg,
      var(--brand-primary) $gradient,
      transparent $gradient
    );
  }

  .kpb-map {
    circle {
      fill: white;
    }
    &__address {
      display: none;
    }
  }

  .contact-grid {
    background-color: #f0fdaf;

    @include respond-above('tablet') {
      padding: 3rem;
      gap: 8rem;
    }

    @include respond-below('tablet') {
      padding: 1rem;
      gap: 4rem;
    }
  }

  .intro {
    &-text {
      display: flex;
      gap: 0.25rem 2rem;
      flex-wrap: wrap;
    }

    &-description {
      @include respond-above('phone') {
        font-size: var(--font-size-h3);
      }
    }
  }

  .hero-container {
    @include respond-below('tablet') {
      padding: 0;
    }
  }

  .environment-gallery {
    @include respond-above('phone') {
      grid-column: 2 / span 2;

      .kpb-gallery__item {
        grid-column-end: span 2;
      }
    }
  }

  .owner-gallery {
    @include respond-above('phone') {
      grid-column: 2 / span 2;
      grid-template-columns: repeat(3, 1fr);
      .kpb-gallery__item {
        max-width: 400px;
        grid-column: 2 / -1;
      }
    }
  }
}
</style>
