<script setup>
import { computed, ref } from 'vue'
import Slider from '@kvass/pagebuilder/src/components/Slider.vue'

const props = defineProps({
  images: Array,
  showCaption: {
    type: Boolean,
    default: true,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
})

const root = ref()

function trigger() {
  let firstSlide = root.value.querySelector('.kpb-photoswipe__item')
  if (!firstSlide) return
  firstSlide.click()
}
</script>

<template>
  <Slider :auto-play="autoPlay" :photo-swipe="true" :data="props.images" v-bind="$attrs"></Slider>
</template>

<style lang="scss">
.gallery {
  margin: 0;

  img {
    cursor: pointer;
    width: 100%;
  }

  figcaption {
    margin-top: 1rem;
  }
}
</style>
